import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { X } from 'react-bootstrap-icons';

function DraggableModal(props) {
	const [disabled, setDisabled] = useState(true);

	// const onStart = (_event, uiData) => {
	// 	const { clientWidth, clientHeight } = window.document.documentElement;
	// 	const targetRect = draggableRef.current?.getBoundingClientRect();
	// 	if (!targetRect || disabled) {
	// 		return;
	// 	}
	//
	// 	setBounds({
	// 		left: -targetRect.left + uiData.x,
	// 		right: clientWidth - (targetRect.right - uiData.x),
	// 		top: -targetRect.top + uiData.y,
	// 		bottom: clientHeight - (targetRect.bottom - uiData.y),
	// 	});
	// };

	const handleClose = (e) => {
		props.onCancel(e);
	}

	return (
		<Modal dialogClassName="custom-modal-width" show={props.open} onHide={handleClose}>
			<Modal.Header>
				<Modal.Title>
					{props.modalTitle}
				</Modal.Title>

				<div className="ms-auto">
					{props.buttons}

					<Button variant="none" className="ms-auto" onClick={handleClose}>
						<X size={20} />
					</Button>
				</div>

			</Modal.Header>
			<Modal.Body>
				{props.children}
			</Modal.Body>
		</Modal>
	);
};

export default DraggableModal;