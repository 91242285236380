import React from "react";
import { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Screenshots from "./Screenshots";
import Videos from "./Videos";
import KeyLogs from "./KeyLogs";
import Audios from "./Audios";
import Browsers from "./Browsers";
import GrabbedItems from "./GrabbedItems";

function ControlledTabsExample({ deviceId, screenshotTime, videoTime }) {
  const [key, setKey] = useState('screenshots');

  return (
    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
    >
      <Tab eventKey="screenshots" title="Screenshots">
        {key === 'screenshots' && <Screenshots deviceId={deviceId} screenshotTime={screenshotTime}/>}
      </Tab>

      <Tab eventKey="videos" title="Desktop">
        {key === 'videos' && <Videos deviceId={deviceId} videoTime={videoTime}/>}
      </Tab>

      {/*<Tab eventKey="audios" title="Audios">*/}
      {/*  {key === 'audios' && <Audios/>}*/}
      {/*</Tab>*/}

      <Tab eventKey="key_logs" title="Key Logs">
        {key === 'key_logs' && <KeyLogs deviceId={deviceId}/>}
      </Tab>

      <Tab eventKey="grabbed" title="Grabbed">
        {key === 'grabbed' && <GrabbedItems deviceId={deviceId}/>}
      </Tab>
    </Tabs>
  );
}

export default ControlledTabsExample;