import React from 'react';
import en from 'antd/lib/locale/en_US';
import {ConfigProvider} from "antd";
import './wrapper.css';


function wrapper(props) {
	return (
		<ConfigProvider locale={en}>
			{props.children}
		</ConfigProvider>
	);
}


export default wrapper;