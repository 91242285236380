import React from 'react';

function DeleteDeviceButton({ deviceId, children }) {
  const handleDelete = async () => {
    if (window.confirm("Are you sure?")) {
      try {
        const response = await fetch(`/devices/${deviceId}.json`, {method: 'DELETE' });

        if (response.ok) {
          window.location.href = '/devices';
        }
      } catch (error) {
        // Handle JS errors
        console.error('Error:', error);
      }
    }
  };

  return (
    <button onClick={handleDelete} className='btn btn-outline-danger'>
      {children}
    </button>
  );
}

export default DeleteDeviceButton;