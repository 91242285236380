import React from 'react'
import { Controller } from "@hotwired/stimulus"
import ReactDOM from "react-dom";
import Overview from "../spark/components/overview"

export default class extends Controller {
  connect() {
    const ds = this.element.dataset;

    console.log(ds)

    ReactDOM.render(
      <Overview clientUUID={ds.clientUuid} title={ds.title} deviceId={ds.deviceId}/>,
      this.element
    );
  }
}
