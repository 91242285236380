
import DraggableModal from "../../modal";

import React, { useEffect, useState } from "react";
import axios from 'axios';

export default ({ url, open, setOpen }) => {
  const [text, setText] = useState('');

  useEffect(() => {
    console.log('open', url)

    if (open) {
      axios.get(url).then((response) => {
        setText(response.data);
      })
    } else {
      setText('');
    }
  }, [open])

  return (
    <DraggableModal modalTitle="Key Log" open={open} onCancel={(e) => setOpen(false)}>
      <div className='preview-keylog'>{text}</div>
    </DraggableModal>
  )
}