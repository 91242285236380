import React from "react";
import {Button, Dropdown, Menu, Space} from "antd";
import i18n from "../../locale/locale";

export default class ExtKeyboard extends React.Component {
  constructor(props) {
    super(props);
    this.open = props.open;
    if (!this.open) return;
    this.funcKeys = [
      {key: '\x1B\x4F\x50', label: 'F1'},
      {key: '\x1B\x4F\x51', label: 'F2'},
      {key: '\x1B\x4F\x52', label: 'F3'},
      {key: '\x1B\x4F\x53', label: 'F4'},
      {key: '\x1B\x5B\x31\x35\x7E', label: 'F5'},
      {key: '\x1B\x5B\x31\x37\x7E', label: 'F6'},
      {key: '\x1B\x5B\x31\x38\x7E', label: 'F7'},
      {key: '\x1B\x5B\x31\x39\x7E', label: 'F8'},
      {key: '\x1B\x5B\x32\x30\x7E', label: 'F9'},
      {key: '\x1B\x5B\x32\x31\x7E', label: 'F10'},
      {key: '\x1B\x5B\x32\x33\x7E', label: 'F11'},
      {key: '\x1B\x5B\x32\x34\x7E', label: 'F12'},
    ];
    this.specialKeys = [
      {key: '\x1B\x5B\x31\x7E', label: 'HOME'},
      {key: '\x1B\x5B\x32\x7E', label: 'INS'},
      {key: '\x1B\x5B\x33\x7E', label: 'DEL'},
      {key: '\x1B\x5B\x34\x7E', label: 'END'},
      {key: '\x1B\x5B\x35\x7E', label: 'PGUP'},
      {key: '\x1B\x5B\x36\x7E', label: 'PGDN'},
    ];
    this.funcMenu = (
      <Menu onClick={this.onKey.bind(this)}>
        {this.funcKeys.map(e =>
          <Menu.Item key={e.key}>
            {e.label}
          </Menu.Item>
        )}
      </Menu>
    );
    this.specialMenu = (
      <Menu onClick={this.onKey.bind(this)}>
        {this.specialKeys.map(e =>
          <Menu.Item key={e.key}>
            {e.label}
          </Menu.Item>
        )}
      </Menu>
    );
    this.state = {
      ctrl: false,
      fileSelect: false,
    };
  }

  onCtrl() {
    this.setState({ctrl: !this.state.ctrl});
    this.props.onCtrl(!this.state.ctrl);
  }
  onKey(e) {
    this.props.onExtKey(e.key, false);
  }
  onExtKey(key) {
    this.props.onExtKey(key, true);
  }
  onFileSelect() {
    if (typeof this.state.fileSelect === 'function') {
      this.state.fileSelect();
    }
  }

  setCtrl(val) {
    this.setState({ctrl: val});
  }
  setFileSelect(cb) {
    this.setState({fileSelect: cb});
  }

  render() {
    if (!this.open) return null;
    return (
      <Space style={{paddingBottom: 12}}>
        <>
          <Button
            type={this.state.ctrl ? 'primary' : 'default'}
            onClick={this.onCtrl.bind(this)}
          >
            CTRL
          </Button>
          <Button
            onClick={this.onExtKey.bind(this, '\x1B')}
          >
            ESC
          </Button>
          <Button
            onClick={this.onExtKey.bind(this, '\x09')}
          >
            TAB
          </Button>
        </>
        <>
          <Button
            onClick={this.onExtKey.bind(this, '\x1B\x5B\x41')}
          >
            ⬆
          </Button>
          <Button
            onClick={this.onExtKey.bind(this, '\x1B\x5B\x42')}
          >
            ⬇
          </Button>
          <Button
            onClick={this.onExtKey.bind(this, '\x1B\x5B\x43')}
          >
            ➡
          </Button>
          <Button
            onClick={this.onExtKey.bind(this, '\x1B\x5B\x44')}
          >
            ⬅
          </Button>
        </>
        <Dropdown.Button
          overlay={this.specialMenu}
        >
          {i18n.t('TERMINAL.SPECIAL_KEYS')}
        </Dropdown.Button>
        <Dropdown.Button
          overlay={this.funcMenu}
        >
          {i18n.t('TERMINAL.FUNCTION_KEYS')}
        </Dropdown.Button>
        {
          this.state.fileSelect?(
            <Button onClick={this.onFileSelect.bind(this)}>
              选择文件
            </Button>
          ):null
        }
      </Space>
    );
  }
}