import React, {useEffect, useState} from "react";
import axios from "axios";
import Preview from "./Preview";
import Card from 'react-bootstrap/Card';

export default ({ deviceId }) => {
  const [preview, setPreview] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [grabbedItems, setGrabbedItems] = useState([]);

  useEffect(() => {
    axios.get(`/devices/${deviceId}/grabbed_items.json`).then((response) => {
      setGrabbedItems(response.data)
    }).catch()
  }, []);


  const handlePreview = (url) => {
    setPreview(true);
    setPreviewUrl(url);
  }

  const handleClose = () => {
    setPreview(false);
    setPreviewUrl(null);
  }

  return (
    <>
      <Preview url={previewUrl} open={preview} setOpen={handleClose}/>

      {grabbedItems.map((grabbedItem) => (
        <Card className="mb-4">
          <Card.Body>
            <Card.Title>Grabber</Card.Title>
            <Card.Text>
              <ol className="list-group list-group-flush">
                {grabbedItem.files.map((file) => (
                  <li className="list-group-item d-flex justify-content-between align-items-start">
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">
                        <a onClick={() => handlePreview(file.url)}>{file.filename}</a>
                      </div>
                    </div>
                    <div>
                      <a onClick={() => handlePreview(file.url)} className="p-1">Preview</a>
                      <a href={file.url} target="_blank" className="p-5">Download</a>
                      <span className="ms-2 badge bg-primary rounded-pill">{file.size}</span>
                    </div>
                  </li>
                ))}
              </ol>
            </Card.Text>
          </Card.Body>
        </Card>
      ))}
    </>
  )
}