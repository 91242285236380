import React from 'react';
import i18n from "../../locale/locale";
import {formatSize} from "../../utils/utils";

export function renderRAMStat(info) {
    let { usage, total, used } = info;
    usage = Math.round(usage * 100) / 100;
    return (
        <div>
            {i18n.t('OVERVIEW.RAM_USAGE') + i18n.t('COMMON.COLON') + usage + '%'}
            <br />
            {i18n.t('OVERVIEW.FREE') + i18n.t('COMMON.COLON') + formatSize(total - used)}
            <br />
            {i18n.t('OVERVIEW.USED') + i18n.t('COMMON.COLON') + formatSize(used)}
            <br />
            {i18n.t('OVERVIEW.TOTAL') + i18n.t('COMMON.COLON') + formatSize(total)}
        </div>
    );
}

export function renderDiskStat(info) {
    let { usage, total, used } = info;
    usage = Math.round(usage * 100) / 100;
    return (
        <div>
            {i18n.t('OVERVIEW.DISK_USAGE') + i18n.t('COMMON.COLON') + usage + '%'}
            <br />
            {i18n.t('OVERVIEW.FREE') + i18n.t('COMMON.COLON') + formatSize(total - used)}
            <br />
            {i18n.t('OVERVIEW.USED') + i18n.t('COMMON.COLON') + formatSize(used)}
            <br />
            {i18n.t('OVERVIEW.TOTAL') + i18n.t('COMMON.COLON') + formatSize(total)}
        </div>
    );
}
export function renderNetworkIO(device) {
    // Make unit starts with Kbps.
    let sent = device.net_sent * 8 / 1024;
    let recv = device.net_recv * 8 / 1024;
    return `${format(sent)} ↑ / ${format(recv)} ↓`;

    function format(size) {
        if (size <= 1) return '0 Kbps';
        // Units array is large enough.
        let k = 1024,
            i = Math.floor(Math.log(size) / Math.log(k)),
            units = ['Kbps', 'Mbps', 'Gbps', 'Tbps'];
        return (size / Math.pow(k, i)).toFixed(1) + ' ' + units[i];
    }
}


export function renderCPUStat(cpu) {
    let { model, usage, cores } = cpu;
    usage = Math.round(usage * 100) / 100;
    cores = {
        physical: Math.max(cores.physical, 1),
        logical: Math.max(cores.logical, 1),
    }
    return (
        <div>
            <div
                style={{
                    fontSize: '10px',
                }}
            >
                {model}
            </div>
            {i18n.t('OVERVIEW.CPU_USAGE') + i18n.t('COMMON.COLON') + usage + '%'}
            <br />
            {i18n.t('OVERVIEW.CPU_LOGICAL_CORES') + i18n.t('COMMON.COLON') + cores.logical}
            <br />
            {i18n.t('OVERVIEW.CPU_PHYSICAL_CORES') + i18n.t('COMMON.COLON') + cores.physical}
        </div>
    );
}