import React from "react";
import DraggableModal from "../../modal";
import ReactPlayer from 'react-player/lazy'

export default ({ url, open, setOpen }) => {
  return (
    <DraggableModal modalTitle="Video" open={open} onCancel={(e) => setOpen(false)}>
      <ReactPlayer url={url} width={1150} height={600} controls/>
    </DraggableModal>
  )
}