import React, {useCallback} from 'react';
import i18n from "../../locale/locale";
import DraggableModal from "../modal";
import {decrypt, encrypt, genRandHex, getBaseURL, hex2ua, str2ua, translate, ua2hex, ua2str} from "../../utils/utils";
import {message} from "antd";

let ws = null;
let conn = false;
let secret = null;
let canvas = null;
let audioContext = null;

function Microphone(props) {
	const canvasRef = useCallback((e) => {
		if (e && props.open && !conn && !canvas) {
			secret = hex2ua(genRandHex(32));
			canvas = e;
			audioContext = new (window.AudioContext || window.webkitAudioContext)();
			construct(audioContext);
		}
	}, [props]);


	function construct(audioContext) {
		if (ws !== null && conn) {
			ws.close();
		}
		ws = new WebSocket(getBaseURL(true, `api/device/microphone?device=${props.device.id}&secret=${ua2hex(secret)}`));
		ws.binaryType = 'arraybuffer';
		ws.onopen = () => {
			conn = true;
		}
		ws.onmessage = (e) => {
			onWsMessage(audioContext, e.data);
		};
		ws.onclose = () => {
			if (conn) {
				conn = false;
				message.warn(i18n.t('COMMON.DISCONNECTED'));
			}
		};
		ws.onerror = (e) => {
			console.error(e);
			if (conn) {
				conn = false;
				message.warn(i18n.t('COMMON.DISCONNECTED'));
			} else {
				message.warn(i18n.t('COMMON.CONNECTION_FAILED'));
			}
		};
	}

	function playAudioData(audioContext, audioData) {
		// Create an AudioBuffer (1 second long, assuming 44100 sample rate for simplicity)
		const buffer = audioContext.createBuffer(1, 44100, 44100);
		const channelData = buffer.getChannelData(0);

		// Ensure audioData is a DataView
		const dataView = new DataView(audioData); // Correctly create a DataView instance

		// Assuming 16-bit PCM data, little-endian
		for (let i = 0; i < dataView.byteLength; i += 2) {
			const value = dataView.getInt16(i, true); // Correct usage of getInt16
			channelData[i / 2] = value / 32768.0; // Normalize to [-1, 1] range
		}

		const source = audioContext.createBufferSource();
		source.buffer = buffer;
		source.connect(audioContext.destination);
		source.start();
	}

	function onWsMessage(audioContext, data) {
		data = new Uint8Array(data);
		if (data[0] === 34 && data[1] === 22 && data[2] === 19 && data[3] === 17 && data[4] === 11 && data[5] === 0) {
			data = data.slice(8);
			const audioData = new Uint8Array(data);
			playAudioData(audioContext, audioData.buffer);
		} else {
			data = decrypt(data, secret);
			try {
				data = JSON.parse(data);
			} catch (_) {}
			if (conn) {
				if (data?.act === 'MICROPHONE_OUTPUT') {
					data = hex2ua(data?.data?.output);
					if (zsentry === null) {
						onOutput(ua2str(data));
					} else {
						try {
							zsentry.consume(data);
						} catch (e) {
							console.error(e);
						}
					}
					return;
				}
				if (data?.act === 'WARN') {
					message.warn(data.msg ? translate(data.msg) : i18n.t('COMMON.UNKNOWN_ERROR'));
					return;
				}
				if (data?.act === 'QUIT') {
					message.warn(data.msg ? translate(data.msg) : i18n.t('COMMON.UNKNOWN_ERROR'));
					ws.close();
					return;
				}
			}
		}
	}

	function afterClose() {
		if (conn) {
			sendData({act: 'MICROPHONE_KILL'});
			ws.onclose = null;
			ws.close();
			conn = false;
			ws = null;
			secret = null;
			canvas = null;

			console.log('removed all')
		}
	}

	function sendData(data, raw) {
		if (conn) {
			let body = [];
			if (raw) {
				if (data.length > 65536) {
					let offset = 0;
					while (offset < data.length) {
						let chunk = data.slice(offset, offset + 65536);
						sendData(chunk, true);
						offset += chunk.length;
					}
				} else {
					body = data;
				}
			} else {
				body = encrypt(str2ua(JSON.stringify(data)), secret);
			}
			let buffer = new Uint8Array(body.length + 8);
			buffer.set(new Uint8Array([34, 22, 19, 17, 11, raw ? 0 : 1]), 0);
			buffer.set(new Uint8Array([body.length >> 8, body.length & 0xFF]), 6);
			buffer.set(body, 8);
			ws.send(buffer);
		}
	}

	return (
		<DraggableModal
			draggable={true}
			maskClosable={false}
			destroyOnClose={true}
			afterClose={afterClose}
			modalTitle={i18n.t('Microphone')}
			footer={null}
			width={500}
			bodyStyle={{
				padding: 0
			}}
			{...props}
		>
			<div ref={canvasRef} style={{ height: 200, backgroundColor: 'black' }}></div>
		</DraggableModal>
	)
}

export default Microphone;