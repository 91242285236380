import React, {useEffect, useState} from "react";
import axios from "axios";
import Preview from "./Preview";

export default ({ deviceId }) => {
  const [preview, setPreview] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [keyLogs, setKeyLogs] = useState([]);

  useEffect(() => {
    axios.get(`/devices/${deviceId}/key_logs.json`).then((response) => {
      setKeyLogs(response.data)
    }).catch()
  }, []);


  const handlePreview = (url) => {
    setPreview(true);
    setPreviewUrl(url);
  }

  const handleClose = () => {
    setPreview(false);
    setPreviewUrl(null);
  }

  return (
    <>
      <Preview url={previewUrl} open={preview} setOpen={handleClose}/>

      <ol className="list-group list-group-flush">
        {keyLogs.map((keyLog) => (
          <li className="list-group-item d-flex justify-content-between align-items-start">
            <div className="ms-2 me-auto">
              <div className="fw-bold">
                <a onClick={() => handlePreview(keyLog.url)}>{keyLog.filename}</a>
              </div>
            </div>
            <div>
              <a onClick={() => handlePreview(keyLog.url)} className="p-1">Preview</a>
              <a href={keyLog.url} target="_blank" className="p-5">Download</a>
              <span className="ms-2 badge bg-primary rounded-pill">{keyLog.size}</span>
            </div>
          </li>
        ))}
      </ol>
    </>
  )
}