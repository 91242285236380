import React, {useEffect, useState} from "react";
import axios from "axios";

export default ({ deviceId, files }) => {


	return (
		<div className="dropdown">
			<button className="btn btn-secondary dropdown-toggle"
							type="button"
							id="dropdownMenuButton"
							data-bs-toggle="dropdown" aria-expanded="false">
				Files
			</button>
			<ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
				{files.map(file =>
					<li key={file.filename}>
						<a className="dropdown-item" href={file.url}>
							{file.filename}
						</a>
					</li>
				)}
			</ul>
		</div>
	)
}
