import React from 'react';
import i18n from "../../locale/locale";
import classNames from "classnames";
import Dropdown from 'react-bootstrap/Dropdown';

const MENUS = [
    { key: 'microphone', name: i18n.t('OVERVIEW.MICROPHONE')},
    // { key: 'lock', name: i18n.t('OVERVIEW.LOCK')},
    // { key: 'logoff', name: i18n.t('OVERVIEW.LOGOFF')},
    // { key: 'hibernate', name: i18n.t('OVERVIEW.HIBERNATE')},
    // { key: 'suspend', name: i18n.t('OVERVIEW.SUSPEND')},
    // { key: 'restart', name: i18n.t('OVERVIEW.RESTART')},
    // { key: 'shutdown', name: i18n.t('OVERVIEW.SHUTDOWN')},
    // { key: 'offline', name: i18n.t('OVERVIEW.OFFLINE')},
];

export default ({ device, onMenuClick, files }) => {
  const disabled = device === null || device === undefined;

  const btnClass = classNames(
    'btn',
    { 'btn-outline-secondary': disabled },
    { 'btn-outline-primary': !disabled }
  )

  return (
    <div>
      <div className="btn-group pb-3 btn-device-menu" role="group" aria-label="Basic">
        <button className={btnClass} disabled={disabled} onClick={() => onMenuClick('terminal', device)}>
          <i className="bi bi-terminal-fill"></i>
          &nbsp;
          {i18n.t('OVERVIEW.TERMINAL')}
        </button>
        <button className={btnClass} disabled={disabled} onClick={() => onMenuClick('explorer', device)}>
          <i className="bi bi-files-alt"></i>
          &nbsp;
          {i18n.t('OVERVIEW.EXPLORER')}
        </button>
        <button className={btnClass} disabled={disabled} onClick={() => onMenuClick('procmgr', device)}>
          <i className="bi bi-card-list"></i>
          &nbsp;
          {i18n.t('OVERVIEW.PROC_MANAGER')}
        </button>
        <button className={btnClass} disabled={disabled} onClick={() => onMenuClick('execute', device)}>
          <i className="bi bi-c-square-fill"></i>
          &nbsp;
          {i18n.t('OVERVIEW.EXECUTE')}
        </button>
        <button className={btnClass} disabled={disabled} onClick={() => onMenuClick('desktop', device)}>
          <i className="bi bi-display"></i>
          &nbsp;
          {i18n.t('OVERVIEW.DESKTOP')}
        </button>
      </div>

      <Dropdown className={"ps-1 float-end"}>
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          Grabbers
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {files.map(file => {
            if (file.programFileId === undefined) return;

            return (
              <Dropdown.Item
                key={file.id}
                href="#"
                onClick={() => onMenuClick('grabber', device, { programFileId: file.programFileId })}>
                {file.title}
              </Dropdown.Item>
            )
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}
