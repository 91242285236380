import React, {useState, useMemo, useEffect} from "react";
import { Gallery } from "react-grid-gallery";
import "react-image-lightbox/style.css";
import Player from "./Player";
import axios from "axios";

export default function Videos({ deviceId, videoTime }) {
  const [videos, setVideos] = useState([]);
  const [index, setIndex] = useState(-1);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    axios.get(`/devices/${deviceId}/videos.json`).then((response) => {
      setVideos(response.data)
    }).catch()
  }, [videoTime])

  const handleClick = (index, item) => {
    setIndex(index);
    setOpen(true);
  }

  const handleClose = () => {
    setIndex(-1);
    setOpen(false);
  }

  const url = useMemo(()=> {
    return index !== -1 ? videos[index].pth : null;
  }, [index])

  return (
    <div>
      <Player url={url} open={open} setOpen={() => handleClose()}/>
      <Gallery
        images={videos}
        onClick={handleClick}
        enableImageSelection={false}
      />
    </div>
  );
}