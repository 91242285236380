import React from "react";
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import i18n from "../../locale/locale";
import UsageBar from "./UsageBar";
import {renderCPUStat, renderDiskStat, renderNetworkIO, renderRAMStat} from "./fucntions";
import {formatSize, tsToTime} from "../../utils/utils";


const ExamplePopover = ({ item }) => {
  const popover = (
    <Popover id="popover-basic" style={{ width: '375px', maxWidth: '375px' }}>
      <Popover.Header as="h3">System</Popover.Header>
      <Popover.Body>
        <div>
          <div className="card-row">
            <div className="attr-name">{i18n.t('OVERVIEW.CPU_USAGE')}</div>
            <div className="attr-value"><UsageBar title={renderCPUStat(item.cpu)} {...item.cpu} /></div>
          </div>

          <div className="card-row">
            <div className="attr-name">{i18n.t('OVERVIEW.RAM_USAGE')}</div>
            <div className="attr-value"><UsageBar title={renderRAMStat(item.ram)} {...item.ram}/></div>
          </div>

          <div className="card-row">
            <div className="attr-name">{i18n.t('OVERVIEW.DISK_USAGE')}</div>
            <div className="attr-value"><UsageBar title={renderDiskStat(item.disk)} {...item.disk}/></div>
          </div>

          <div className="card-row">
            <div className="attr-name">{i18n.t('OVERVIEW.RAM')}</div>
            <div className="attr-value">{formatSize(item.ram_total)}</div>
          </div>

          <div className="card-row">
            <div className="attr-name">{i18n.t('OVERVIEW.OS')}</div>
            <div className="attr-value">{item.os}</div>
          </div>

          <div className="card-row">
            <div className="attr-name">{i18n.t('OVERVIEW.ARCH')}</div>
            <div className="attr-value">{item.arch}</div>
          </div>

          <div className="card-row">
            <div className="attr-name">MAC</div>
            <div className="attr-value">{item.mac}</div>
          </div>

          <div className="card-row">
            <div className="attr-name">LAN</div>
            <div className="attr-value">{item.lan}</div>
          </div>

          <div className="card-row">
            <div className="attr-name">WAN</div>
            <div className="attr-value">{item.wan}</div>
          </div>

          <div className="card-row">
            <div className="attr-name">{i18n.t('OVERVIEW.UPTIME')}</div>
            <div className="attr-value">{tsToTime(item.uptime)}</div>
          </div>

          <div className="card-row">
            <div className="attr-name">{i18n.t('OVERVIEW.NETWORK')}</div>
            <div className="attr-value">{renderNetworkIO(item)}</div>
          </div>
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger trigger="click" placement="bottom-end" overlay={popover}>
      <i className="bi bi-info-circle"></i>
    </OverlayTrigger>
  );
};

export default ({item}) => {
  if (!item) return null;

  return (
    <div style={{width: 700}}>
      <div style={{paddingTop: 3}}>
        <div className='card-row'>
          <div className="title-attr-name">{i18n.t('OVERVIEW.HOSTNAME')}:</div>
          <div className="title-attr-value relative comp-title">{item.hostname}</div>
          <div className="title-attr-name">{i18n.t('OVERVIEW.USERNAME')}:</div>
          <div className="title-attr-value">{item.username}</div>
          <div className="title-attr-name">Ping:</div>
          <div className="title-attr-value">{String(item.latency) + 'ms'}</div>
          <ExamplePopover item={item}/>
        </div>
      </div>
    </div>
  )
}
