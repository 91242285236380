import React from 'react';
import {Progress, Tooltip} from "antd";

function UsageBar(props) {
    let {usage} = props;
    usage = usage || 0;
    usage = Math.round(usage * 100) / 100;

    return (
        <Tooltip
            title={props.title??`${usage}%`}
            overlayInnerStyle={{
                whiteSpace: 'nowrap',
                wordBreak: 'keep-all',
                maxWidth: '300px',
            }}
            overlayStyle={{
                maxWidth: '300px',
            }}
        >
            <Progress percent={usage} showInfo={false} strokeWidth={12} trailColor='#FFECFF'/>
        </Tooltip>
    );
}

export default UsageBar;