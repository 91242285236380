import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.typeEl = this.element.querySelector('#program_file_file_type')
    this.filenameBlock = this.element.querySelector('.program_file_filename')
    this.contentBlock = this.element.querySelector('.program_file_content')
    this.fileBlock = this.element.querySelector('.program_file_file')
    this.titleBlock = this.element.querySelector('.program_file_title')
    this.folderBlock = this.element.querySelector('.program_file_folder')

    this.onChangeType();
  }

  onChangeType() {
    const type = this.typeEl.value;

    console.log('onChangeType', type)
    console.log('asdfasf', this.filenameBlock)

    switch (type) {
      case 'erb':
        this.filenameBlock.classList.remove('d-none')
        this.contentBlock.classList.remove('d-none')
        this.titleBlock.classList.add('d-none')
        this.folderBlock.classList.add('d-none')
        this.fileBlock.classList.add('d-none')
        break;
      case 'file':
        this.filenameBlock.classList.add('d-none')
        this.contentBlock.classList.add('d-none')
        this.titleBlock.classList.add('d-none')
        this.folderBlock.classList.add('d-none')
        this.fileBlock.classList.remove('d-none')
        break;
      case 'grabber':
        this.filenameBlock.classList.add('d-none')
        this.contentBlock.classList.add('d-none')
        this.titleBlock.classList.remove('d-none')
        this.folderBlock.classList.remove('d-none')
        this.fileBlock.classList.remove('d-none')
        break;
      default:
        this.filenameBlock.classList.add('d-none')
        this.contentBlock.classList.add('d-none')
        this.titleBlock.classList.add('d-none')
        this.folderBlock.classList.add('d-none')
        this.fileBlock.classList.add('d-none')
        break
    }
  }
}
